import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import CheckInService from '../../../api/CheckInService.js'
import axios from 'axios'

export default{
	data(){
		return{
			newsInfo:'',
			hotNewsList:[],
			HotDynamicList:[],
			newsFileList:[]
		}
	},
	methods:{
		GetNewsDetail(){ //获取新闻详情
			var that = this
			CheckInService.GetNewsDetail(that.$route.query.id,that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.newsInfo = res.Result 
				}
			})
		},
		GetHotNewsList(){ //获取热门新闻
			var that = this
			CheckInService.GetNewsHotList(5).then(res=>{
				if(res.Tag == 1){
					that.hotNewsList = res.Result
				}
			})
		},
		GetHotDynamicList(){ //获取热门动态
			var that = this
			CheckInService.GetHotAcademicDynamicList(5).then(res=>{
				if(res.Tag == 1){
					that.HotDynamicList = res.Result
				}
			})
		},
		GetNewsFile(){ //获取新闻附件
			var that = this
			CheckInService.GetNewsFile(that.$route.query.id).then(res=>{
				if(res.Tag == 1){
					that.newsFileList = res.Result
				}
			})
		},
		downLoadFile(FilePath, FileName) { //下载文件
			if (!FilePath) {
				return;
			}
			axios
				.get(FilePath, {
					responseType: 'blob'
				})
				.then(response => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					// 返回的header Content-Disposition:attachment; filename=elmeast-report-2018-2.pdf
					// let head = response.headers['content-disposition']
					link.href = url
					let fname = FileName + '.' +FilePath.split('.')[1]					
					link.setAttribute('download', fname)
					document.body.appendChild(link)
					link.click()
				})
		},
	},
	created(){
		this.GetNewsDetail()
		this.GetHotNewsList()
		this.GetHotDynamicList()
		this.GetNewsFile()
	},
	components:{
		'Header': Header,
		'Footer':Footer
	},
	watch: {
		$route() {
			location.reload()
		}
	}
}